export type ToastParameters =
  | string
  | {
    message?: string
    target?: string
    duration?: number
    persistent?: boolean
    buttons?: Array<{ text: string, onClick: () => void }>
    onClose?: () => void
  }

export type ToastFn = (
  parameters: ToastParameters,
) => void

export interface Toast {
  success: ToastFn
  error: ToastFn
  info: ToastFn
}

function noop() {
  console.error('Toast not found through injection')
}

export function getToast() {
  return inject(diKeys.toast, {
    success: noop,
    error: noop,
    info: noop,
  })
}
