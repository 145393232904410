import type { InjectionKey } from 'vue'
import type { Toast } from '~/utils/toast'

/**
 * Dependency injection keys. Use these to inject dependencies into components.
 *
 * Example:
 * const toast = inject(diKeys.toast)
 *
 * Add new keys using Symbols to avoid collisions:
 * export const diKeys = {
 *   ...
 *   myNewKey: Symbol('myNewKey') as InjectionKey<MyType>,
 * }
 */
export const diKeys = {
  toast: 'ksToast' as unknown as InjectionKey<Toast>,
}
